// src/pages/join.tsx
import { useEffect } from "react";
import { navigate } from "gatsby";

const JoinPage: React.FC = () => {
  useEffect(() => {
    navigate('/join-the-herd', { replace: true });
  }, []);

  return null;
};

export default JoinPage;